.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  min-height: 4rem;
  border: 1px solid var(--pico-muted-border-color);
}

.question {
  background-color: var(--pico-primary-background);
  color: var(--pico-primary-inverse);
  border-radius: 1rem;
  width: 90%;
  margin-right: auto;
}

.answer {
  background-color: var(--pico-secondary-background);
  color: var(--pico-secondary-inverse);
  border-radius: 1rem;
  width: 90%;
  margin-left: auto;
}
