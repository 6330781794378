.action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1em;
}

.action.active {
  background-color: var(--pico-contrast-focus);
}

@media (prefers-reduced-motion: no-preference) {
  .progress.active {
    animation: progress-icon-spin infinite 5s linear;
  }
}

.progress {
  color: gray;
}

.progress.active {
  color: red;
}

.progress.complete {
  color: green;
}

@keyframes progress-icon-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
