/* Yellow Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme="light"],
:root:not([data-theme="dark"]) {
  --background-color: rgb(46 46 48);
  --muted-border-color: rgb(66 66 68);
}

/* Yellow Dark scheme (Auto) */
/* Automatically enabled if user has Dark mode enabled */
@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --background-color: rgb(46 46 48);
    --muted-border-color: rgb(66 66 68);
  }
}

/* Yellow Dark scheme (Forced) */
/* Enabled if forced with data-theme="dark" */
[data-theme="dark"] {
  --background-color: rgb(46 46 48);
  --muted-border-color: rgb(66 66 68);
}

/* Yellow (Common styles) */
:root {
  --form-element-active-border-color: var(--pico-primary);
  --form-element-focus-color: var(--pico-primary-focus);
  --switch-color: var(--pico-primary-inverse);
  --switch-checked-background-color: var(--pico-primary);
}
