.todoCreate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.todoCreate .todoCreateButton {
  margin-left: 0.5rem;
}

.todoCreate .todoCreateButton:hover {
  color: var(--pico-primary-hover);
}

.todoCreate .todoCreateButton:active {
  color: var(--pico-primary-focus);
}
