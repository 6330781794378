.agendaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.agendaContainer .agendaHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  font-weight: bold;
  background-color: var(--pico-secondary-background);
  color: var(--pico-secondary-inverse);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.agendaContainer .agendaBody {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  padding-top: 1rem;
}
