.meetings {
  display: flex;
  flex-direction: row;
}

.meetingList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 400px;
  width: 100%;
  height: 80vh;
  max-width: 400px;
  margin-right: 1rem;
}

.meetingList header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  font-weight: bold;
  background-color: var(--pico-secondary-background);
  color: var(--pico-secondary-inverse);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.meetingList ul {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--pico-muted-border-color);
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

.meetingList li {
  list-style-type: none;
  width: 100%;
}

.meetingList button {
  display: flex;
  justify-content: center;
  flex-grow: 0;
}

.meetingDetail {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 300px;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

.noContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}
