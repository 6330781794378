.mainNavbar {
  display: flex;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--pico-secondary-background);
  border-bottom: 1px solid var(--pico-muted-border-color);
}

.logo {
  width: 35px;
  height: 35px;
  filter: invert(100%);
}

.title {
  font-weight: bold;
  color: var(--pico-secondary-inverse);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  font-weight: lighter;
  color: var(--pico-secondary-inverse);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mainNavbar a {
  color: var(--pico-secondary-inverse);
}
