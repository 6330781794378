.agendaItem {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  height: 4rem;
}

.agendaItemContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  cursor: pointer;
}

.agendaItemContent:hover {
  border: 1px solid var(--pico-primary-border);
  background-color: var(--pico-secondary-background);
  color: var(--pico-primary-inverse);
  cursor: pointer;
}

.agendaItemContent.active {
  border-width: 1px;
  border-color: var(--pico-primary-focus);
}

.agendaItemArchived {
  background-color: var(--pico-primary-disabled-background);
  text-decoration: line-through;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  // z-index: 100;
}

.agendaItem .agendaItemDetail {
  margin-left: 0.5rem;
  cursor: pointer;
}
