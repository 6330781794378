.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--navbar-height) + 2em);

  .loginForm {
    width: 100%;
    min-width: 400px;
    padding: 1em;
  }

  .footer {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    justify-content: space-between;
    align-items: center;
  }
}


