.noteContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .noteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .title {
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 2rem;
    }

    .shared {
      font-size: 80%;
      font-weight: normal;
      border: 1px solid var(--pico-secondary-background);
      border-radius: 0.5rem;
      color: var(--pico-secondary-inverse);
      background-color: var(--pico-secondary-background);
      padding: 0.25rem 0.5rem;
    }
  }

  .text {
    font-weight: normal;
    font-size: 80%;
    min-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  footer {
    font-size: 80%;
  }
}
