.accountMenu {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.accountName {
    display: flex;
    align-items: center;
    color: var(--pico-secondary-inverse);
}

.chevron {
    margin-left: 5px;
    transition: transform 0.3s ease;
}

.chevron.open {
    transform: rotate(180deg);
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
    white-space: nowrap;
    color: var(--pico-secondary-inverse);
    background-color: var(--pico-secondary-background);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
