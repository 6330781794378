.forgotPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(var(--navbar-height) + 2em);

    h4 {
        margin-top: 1.5em;
    }
    
    .forgotPasswordForm {
        width: 100%;
        min-width: 400px;
        padding: 1em;
      }
}
  