.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileForm {
    width: 100%;
    min-width: 400px;
    padding: 1em;
}
