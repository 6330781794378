ul.nav {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--pico-border-radius);
  border: 1px solid gray;
  padding: 0;
}

ul.nav li {
  width: 50%;
  list-style: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.7s;
  margin: 0;
}

ul.nav li:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

ul.nav li:last-child {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

ul.nav li:hover {
  background: var(--pico-secondary-hover);
  color: var(--pico-primary-inverse);
  text-decoration: underline;
}

ul.nav li.active {
  background: var(--pico-secondary-focus);
  color: var(--pico-primary-inverse);
  text-decoration: underline;
}
