.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--pico-secondary-background);
}

.navbar .linkText {
  display: block;
}

.navbarNav {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navItem {
  width: 100%;
}

.navItem select:focus {
  outline: none;
}

.navItem .navSeparator {
  border-bottom: 1px solid var(--pico-muted-border-color);
}

.navLink {
  display: flex;
  align-items: center;
  height: 3rem;
  color: var(--pico-secondary-inverse);
}

.navLink:hover {
  background: var(--pico-primary-hover-background);
  color: var(--pico-primary-inverse);
  text-decoration: none;
}

.navLink.active {
  background: var(--pico-primary-background);
  color: var(--pico-primary-inverse);
}

.linkText {
  display: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--pico-secondary-inverse);
}

.navLink .icon {
  margin: 0 1.5rem;
  font-size: 1.3em;
}

.navLink:hover .icon {
  color: var(--pico-secondary-inverse);
}

.assistantIcon {
  margin-right: 0.5rem;
  color: var(--pico-primary-inverse);
}
