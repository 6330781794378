.meetingsDashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.meetingsDashboardMain {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 2;
  flex-basis: 70%;
  height: 100%;
  overflow: auto;
}

.meetingsDashboardSidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 2;
  flex-basis: 30%;
}

.agenda {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  border: 1px solid var(--pico-muted-border-color);
  margin-bottom: 0.25rem;
}

.todos {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  /* border: 1px solid var(--pico-muted-border-color); */
  margin-top: 0.25rem;
  height: 100%;
}
