.messages {
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        scrollbar-width: none; /* For Firefox */
    }

    li {
        list-style-type: none;
        width: 100%;
    }
}

.messages::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
}
