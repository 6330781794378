.multiAction {
  display: flex;
  flex-direction: column;
  width: 400px;
}

ul.multiAction li {
  list-style: none;
  border: 1px solid black;
}

ul.multiAction li:first-child {
  border-bottom: 0;
}

ul.multiAction li:last-child {
  border-top: 0;
}
