.notesHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 8rem;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    font-weight: bold;
    background-color: var(--pico-secondary-background);
    color: var(--pico-secondary-inverse);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .noteControls {
    display: flex;
    flex-direction: row;
    align-items: center;

    .search {
      width: 100%;
      flex-grow: 0;
      margin: 1rem;
    }

    .sortControls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      margin: 1rem;
    }

    .sortControl {
      color: var(--pico-primary);
    }

    .sortControl:hover {
      color: var(--pico-primary-hover);
      cursor: pointer;
    }
  }
}
