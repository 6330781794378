.todo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.25rem;
}

.todo .todoComplete {
  margin-right: 1rem;
}

.todo .todoTitle {
  margin: 0;
}

.todo .todoDeleteButton {
  margin-left: 0.5rem;
}

.todo .todoDeleteButton:hover {
  color: var(--pico-form-element-invalid-active-border-color);
}

.todo .todoDeleteButton:active {
  color: var(--pico-form-element-invalid-active-border-color);
}
