.todos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  border: 1px solid var(--pico-muted-border-color);
}

.todos::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}
