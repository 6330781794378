.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--navbar-height) + 2em);
}

.registerForm {
  width: 100%;
  min-width: 400px;
  padding: 1em;
}
