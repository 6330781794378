.menuItem {
    padding: 10px 20px;
    cursor: pointer;

    a {
        text-decoration: none;
    }
}

.menuItem:hover {
    background-color: var(--pico-secondary);
}
