.meetingItem {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border: 1px solid var(--pico-muted-border-color);
  width: 100%;
  height: 6rem;
  border-radius: var(--pico-border-radius);
  scrollbar-width: none; /* For Firefox */
  margin-bottom: 0.5rem;
  border-left-width: var(--item-border-width);
}

.meetingItem:hover {
  border: 1px solid var(--pico-primary-border);
  border-left-width: var(--item-border-width);
  background-color: var(--pico-secondary-background);
  color: var(--pico-primary-inverse);
  cursor: pointer;
}

.meetingItem.active {
  border-width: 1px;
  border-left-width: var(--item-border-width);
  border-color: var(--pico-primary-focus);
}
.meetingContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingContent small {
  font-size: small;
  font-style: italic;
}
