.submitInvite {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
}

.submitInvite input {
  flex: 3;
  padding: 0;
  margin: 0;
  height: 3rem;
  margin-right: 1rem;
  padding-left: 1rem;
}

.submitInvite button {
  flex: 1;
  padding: 0;
  margin: 0;
  height: 3rem;
}

.onboardingFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
