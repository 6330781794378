.noteDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-top: 0rem;
    border: 1px solid var(--pico-muted-border-color);
    border-radius: var(--pico-border-radius);

    li {
      list-style: none;
    }

    .noteTypes {
      margin: 0.5rem;
      flex-shrink: 0;
      flex-grow: 2;
      justify-content: start;
      align-items: center;
    }

    .checkboxContainers {
      display: flex;
      flex-direction: column;
      justify-content: end;
      flex-grow: 0;
    }

    .checkboxContainer {
      display: flex;
      justify-content: start;
      align-items: center;

      label {
        padding-right: 0.5rem;
      }
    }
  }

  .myEditor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
  }
}

.noNotesMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: large;
  font-weight: bold;
}

footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .saveButton {
    margin: 0;
  }

  .deleteLink {
    background: none;
    border: none;
    color: #007bff;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
  }

  .deleteLink:hover,
  .deleteLink:focus {
    color: #0056b3;
    text-decoration: none;
    /* Remove or customize the focus outline as needed */
  }

  .deleteLink:focus {
    outline: 2px solid #0056b3;
    outline-offset: 2px;
  }
}
