.notesDashboard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

.notesDashboardMain {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 400px;
  max-width: 400px;
  height: 80vh;
}

.notesDashboardSide {
  display: flex;
  flex-shrink: 1;
  flex-grow: 2;
  flex-basis: 300px;
  height: 80vh;
}
