.meetingNote {
  display: flex;
  flex-direction: column;
  height: 100%;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .accessTypes {
      margin-right: 1rem;
    }

    .meetingDate {
      margin-right: 1rem;
    }

    .datePicker {
      margin-top: 1rem;
      background-color: var(--pico-background-color);
      align-items: center;
    }
  }

  .myEditor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;

    .deleteLink {
      background: none;
      border: none;
      color: #007bff;
      padding: 0;
      font: inherit;
      cursor: pointer;
      text-decoration: underline;
    }

    .deleteLink:hover,
    .deleteLink:focus {
      color: #0056b3;
      text-decoration: none;
      /* Remove or customize the focus outline as needed */
    }

    .deleteLink:focus {
      outline: 2px solid #0056b3;
      outline-offset: 2px;
    }
  }
}

.noMeetingMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: large;
  font-weight: bold;
  border: 1px solid var(--pico-muted-border-color);
}

footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .saveButton {
    margin: 0;
  }
}
