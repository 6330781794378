[data-theme="light"],
:root:not([data-theme="dark"]) {
  --guru-editor-toolbar: rgb(31, 31, 51);
  --guru-editor-body: rgb(255, 255, 255);
}

[data-theme="dark"] {
  --guru-editor-toolbar: rgb(28, 33, 44);
  --guru-editor-body: rgb(28, 33, 44);
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --guru-editor-toolbar: rgb(28, 33, 44);
    --guru-editor-body: rgb(28, 33, 44);
  }
}

.guru-editor {
  min-height: 300px;
  height: 100%;
  overflow: hidden;
  outline: none;
  resize: none;
  border: 1px solid var(--pico-muted-border-color);
  background-color: var(--guru-editor-body);
  padding: 0.5rem;
}

.guru-editor-placeholder {
  position: absolute;
  top: 15px;
  left: 10px;
  pointer-events: none;
}

.guru-editor-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--pico-border-radius) var(--pico-border-radius) 0 0;
  background-color: var(--pico-secondary-background);
  border-bottom: 1px solid var(--pico-muted-border-color);
}

.guru-editor-toolbar-button {
  background-color: transparent;
  border: none;
  transition: colors 0.1s ease-in;
  margin: 0.25rem;
}

.guru-editor-toolbar-button:hover {
  background-color: #4a5568;
}

.guru-editor-toolbar-button-bgColor {
  background-color: #4a5568;
}

.guru-editor-toolbar-button-bgTransparent {
  background-color: transparent;
}

.bold {
  font-weight: bold;
}

.strikethrough {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.paragraph {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-toolbar-icon {
  color: white;
  width: 0.875rem;
  height: 0.875rem;
}

.toolbar-bar {
  width: 1px;
  background-color: #718096;
  display: block;
  height: 2em;
}

#editor-save-button {
  margin-top: 1em;
}
