:root {
  font-size: 14px;
  font-family: "Open Sans";
  --transition-speed: 600ms;
  --navbar-height: 60px;
  --side-nav-width: 16em;
  --item-border-width: 0.5em;
}

[data-theme="light"],
:root:not([data-theme="dark"]) {
  --pico-border-radius: 0.25rem;
}

[data-theme="dark"] {
  --pico-border-radius: 0.25rem;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --pico-border-radius: 0.25rem;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

select {
  color: var(--pico-primary-inverse);
  background-color: var(--background-color);
  cursor: pointer;
}
.error {
  color: var(--pico-form-element-invalid-active-border-color);
  font-size: 0.8em;
}

input.error,
textarea.error {
  border: 1px solid var(--pico-form-element-invalid-active-border-color);
  border-left: 1em solid var(--pico-form-element-invalid-active-border-color);
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  /* optional: you might want to add some cursor feedback */
  cursor: pointer;
}

.icon-button:hover {
  background: none;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  cursor: pointer;
}

/* Add a margin-left to any anchor tag immediately following another anchor tag */
a + a {
  margin-left: 0.5em;
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Small screens */
@media only screen and (max-width: 600px) {
}
