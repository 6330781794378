.notes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  border: 1px solid var(--pico-muted-border-color);
}

.notes::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.notes ul {
  padding: 0;
}

.notes li {
  list-style-type: none;
  width: 100%;
}

.note {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem;
  border: 1px solid var(--pico-muted-border-color);
  border-left-width: var(--item-border-width);
  height: 7rem;
  border-radius: var(--pico-border-radius);
  margin-bottom: 0.5rem;
}

.note:hover {
  border: 1px solid var(--pico-primary-border);
  border-left-width: var(--item-border-width);
  background-color: var(--pico-secondary-background);
  color: var(--pico-primary-inverse);
  cursor: pointer;
}

.note.selected {
  border-width: 1px;
  border-left-width: var(--item-border-width);
  border-color: var(--pico-primary-focus);
}
