.themeIconLight {
  cursor: pointer;
  color: var(--pico-primary-inverse);
  font-size: 24px;
}

.themeIconLight:hover {
  color: var(--pico-secondary);
}

.themeIconDark {
  cursor: pointer;
  color: var(--pico-primary-inverse);
  font-size: 24px;
}

.themeIconDark:hover {
  color: var(--pico-secondary);
}
