$slide_duration: 0.5s;
$slide_width: 40em;

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.mainNav {
  display: flex;
  width: 100%;
  height: var(--navbar-height);
}
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sideNav {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: calc(100vh - var(--navbar-height));
  width: var(--side-nav-width);
}

.mainArea {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  padding-left: 1em;
  padding-right: 2em;
  transition: margin-left $slide_duration;
  width: 100%;
}

.onboarding {
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding-top: calc(var(--navbar-height) + 2em);
}

.slideRight {
  margin-left: $slide_width;
}

.assistantView {
  position: fixed;
  left: calc(var(--side-nav-width) + 1em);
  top: calc(var(--navbar-height) + 2em);
  width: $slide_width;
  height: 100%;
  opacity: 0;
  animation: fadeIn 0s ease-out $slide_duration forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
