.assistant {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 88vh;
    padding: 2rem;

    .messagesContainer {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        height: 100%;
        overflow: scroll;
        scrollbar-width: none; /* For Firefox */
    }

    .messagesContainer::-webkit-scrollbar {
        display: none; /* For Chrome, Safari and Opera */
    }

    .messageCreateContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
