.messageCreate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6rem;
}

.messageInput {
    height: 100%;
    margin-top: 1em;
}

.messageCreate .messageCreateButton {
    margin-left: 0.5rem;
    margin-top: 0;
}

.messageCreate .messageCreateButton:hover {
    color: var(--pico-primary-hover);
}

.messageCreate .messageCreateButton:active {
    color: var(--pico-primary-focus);
}
