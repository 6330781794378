.agendaItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

.agendaItems::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.agendaItems ul {
  padding: 0;
}

.agendaItems li:first-child {
  border-top: 1px solid var(--pico-muted-border-color);
  border-bottom: 1px solid var(--pico-muted-border-color);
}
.agendaItems li {
  list-style-type: none;
  width: 100%;
  border-bottom: 1px solid var(--pico-muted-border-color);
}
