.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input {
  min-width: 5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 3rem;
  border-radius: 0.25rem;
  background-color: var(--pico-primary-color);
}

.clearSearch {
  margin-left: -3rem;
  cursor: pointer;
}
