.todosContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.todosContainer header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  font-weight: bold;
  background-color: var(--pico-secondary-background);
  color: var(--pico-secondary-inverse);
  border-top-left-radius: var(--pico-border-radius);
  border-top-right-radius: var(--pico-border-radius);
  width: 100%;
}

.todosContainer ul {
  padding: 0.5rem;
}

.todosContainer li {
  list-style-type: none;
  width: 100%;
}
